/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* PrimeReact */
@import "primereact/resources/themes/saga-blue/theme.css"; /* Выберите тему */
@import "primereact/resources/primereact.min.css"; /* Основные стили PrimeReact */
@import "primeicons/primeicons.css"; /* Иконки PrimeIcons */

.p-panel-content {
  /* height: 90vh !important; */
}
